<template lang="pug">
settings-page(title="История уведомлений" isGoBack)

  alert-message(type="default" label="Обратите внимание")
    template(#content)
      | Текст уведомлений описывает примерное содержание письма, поэтому уведомления, связанные с регистрацией/восстановлением пароля
      | не содержат точных данных. В целях безопасности мы не храним пароли в базе данных в открытом виде и не отображаем их на этой странице.

  .notification-table
    ui-table(
      :data="data"
      :loader="isFetching"
      :columns="columns"
      :has-error="error"
      :has-filters="!!(preFiltrationForm.notificationTypes)"
      :header-sticky="true"
      v-model="preFiltrationForm"
      @apply="jumpToPage(1)"
    )
      template(#dateNotify="{ rowData }") {{ formatDateTime(rowData.dateNotify) }}
      template(#isNotified="{ rowData }")
        span(:class="rowData.isNotified ? 'notified' : 'cancelled'") {{ rowData.isNotified ? 'Отправлено' : 'Не отправлено' }}
      template(#notificationType="{ rowData }") {{ rowData.notificationType.title || 'Нет информации' }}
      template(#notificationContent="{ rowData }")
        span.notification-content(v-if="rowData.content" v-html="rowData.content")
        span.no-content(v-else) Нет информации

    ui-pagination(
      :count-results="data.length"
      :total-results="totalResults"
      :current-page="preFiltrationForm.page"
      :total="totalPages"
      @update:current-page="jumpToPage($event, false)"
    )
</template>

<script lang="ts">
import { ref, defineComponent, computed, watch } from "vue";
import { formatDateTime } from "@/utils/formatter/dateFormatter";
import { useApi } from "@/use/api/useApi";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { storeToRefs } from "pinia";

import SettingsPage from "@/components/pages/settings/common/SettingsPage.vue";
import UiTable from "@/components/ui/table/UiTable.vue";
import UiPagination from "@/components/ui/pagination/UiPagination.vue";
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";
import NotificationTypeFilter from "@/components/filters/notifications/NotificationTypeFilter.vue";
import ClientFilter from "@/components/filters/services/ClientFilter.vue";

import useManualsStore from "@/stores/manuals/useManualsStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import type { BaseObjectI } from "@/stores/manuals/ManualsInterface";

interface NotificationHistoryI {
  id: number;
  deliveryType: BaseObjectI;
  notificationType: BaseObjectI;
  dateNotify: string;
  isNotified: boolean;
  content: any;
}

export default defineComponent({
  name: "NotificationHistory",
  components: {
    AlertMessage,
    UiTable,
    UiPagination,
    SettingsPage,
  },
  methods: {
    formatDateTime,
  },
  setup() {

    const data = ref<NotificationHistoryI[]>([]);
    const error = ref(false);

    const isFetching = ref(true);

    const totalPages = ref(1);
    const totalResults = ref(0);

    const manualsStore = useManualsStore();

    const tenmonManagerStore = useTmManagerStore();
    const { selectedCompanyInfo } = storeToRefs(tenmonManagerStore);

    const { isTmManager } = useUserAccess();

    const preFiltrationForm = ref({
      page: 1,
      customerCompany: selectedCompanyInfo.value || null,
      notificationTypes: [],
      sorting: [{ title: 'dateNotify', order: 'desc' }]
    });

    function getTypeFilterValue() {
      return manualsStore.notificationTypes.filter((e: BaseObjectI) => preFiltrationForm.value.notificationTypes.includes(e.id)).map(e => e.title).join(', ')
    }

    function getClientLabel() {
      return preFiltrationForm.value.customerCompany?.title || ''
    }

    const columns = computed(() => ({
      dateNotify: { size: 156, bold: true, headerLabel: 'Дата отправки', isSort: true },
      notificationType: { size: 240, headerLabel: 'Вид уведомления', filterComponent: NotificationTypeFilter, value: getTypeFilterValue() },
      isNotified: { size: 180, headerLabel: 'Статус', invisible: !isTmManager.value },
      notificationContent: { size: isTmManager.value ? 530 : 710, headerLabel: 'Текст уведомления', filterComponent: isTmManager.value && !selectedCompanyInfo.value ? ClientFilter : undefined, value: getClientLabel() },
    }))

    /** получение списка всех уведомлений */
    useApi().notificationHistory.fetchNotificationsList<NotificationHistoryI[]>(preFiltrationForm.value)
      .then((hydraData) => {
        totalResults.value = Number(hydraData['hydra:totalItems'])
        totalPages.value = Math.ceil(hydraData['hydra:totalItems'] === 0 ? 1 : (hydraData['hydra:totalItems'] / 30))
        data.value = hydraData['hydra:member']
      })
      .catch(() => error.value = true)
      .finally(() => isFetching.value = false)

    function jumpToPage(p: number, resetPages = true) {
      preFiltrationForm.value.page = p
      applyFilters(resetPages)
    }

    /** получение списка видов уведомлений (для фильтра) */
    manualsStore.manuals.getNotificationTypes();

    /** фильтрация уведомлений */
    function applyFilters(resetPages: boolean) {
      isFetching.value = true;

      if (resetPages) {
        totalResults.value = 0
        totalPages.value = 1
      }

      data.value = []
      error.value = false

      useApi().notificationHistory.fetchNotificationsList<NotificationHistoryI[]>(preFiltrationForm.value)
        .then((filtersData) => {
          totalResults.value = Number(filtersData['hydra:totalItems'])
          totalPages.value = Math.ceil(filtersData['hydra:totalItems'] === 0 ? 1 : (filtersData['hydra:totalItems'] / 30))
          data.value = filtersData['hydra:member']
        })
        .catch(() => error.value = true)
        .finally(() => isFetching.value = false)
    }

    /** обновление табличных данных при выборе менеджером клиента */
    if (isTmManager.value) {
      watch(selectedCompanyInfo, (v) => {
        preFiltrationForm.value.customerCompany = v || null
        jumpToPage(1)
      })
    }

    return {
      data,
      error,
      isFetching,
      totalPages,
      totalResults,
      columns,
      preFiltrationForm,
      jumpToPage,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixin/links";

$cell-padding: 20px 12px;

.notification-content {
  :deep(a) {
    @include link-mixin;
  }
}

.no-content {
  color: var(--secondary-text-color);
}

.notified {
  color: var(--main-success-color);
}

.cancelled {
  color: var(--main-red-color);
}

.notification-table {
  display: flex;
  flex-flow: column;
  gap: 24px;

  padding: 12px 16px;
  background-color: white;

  border-radius: 4px;
  border: 1px solid #F1F0F0;
}
</style>
