<template lang="pug">
filter-template.filter(
  @reset="cleanFilter"
  @save="applyFilters"
  @cancel="cancel"
)
  template(#header) Вид уведомления
  template(#content)
    checkbox-group(v-model="filters.notificationTypes" :options="manualsStore.notificationTypes" id="notification-types")
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { useFiltersComponent } from "@/use/filters/useFiltersComponent";
import FilterTemplate from "@/components/filters/FilterTemplate.vue";
import CheckboxGroup from "@/components/ui/checkbox/CheckboxGroup.vue";
import useManualsStore from "@/stores/manuals/useManualsStore";

export default defineComponent({
  name: "InnerNotificationFilter",
  components: {
    FilterTemplate,
    CheckboxGroup,
  },
  props: {
    modelValue: {},
  },
  emits: [
    'close',
    'apply',
    'update:modelValue',
  ],
  setup(props, { emit }) {

    const manualsStore = useManualsStore();

    const { modelValue } = toRefs(props);

    const {
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    } = useFiltersComponent(modelValue, false, ['notificationTypes'], emit, 'history')

    return {
      manualsStore,
      filters,
      cancel,
      cleanFilter,
      applyFilters,
    }
  }
})
</script>

<style scoped lang="scss">
.filter {
  width: 320px;
}
</style>
